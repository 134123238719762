const allProfiles = [
    {
        name: "Sean Wibisono",
        ATAR: "99.95 ATAR",
        school: "Dux of North Sydney Boys",
        line1: ["", "1st in Physics (96)"],
        line2: ["", "9th in Chemistry"],
        line3: ["", "9th in English Adv."],
        img: "https://uploads-ssl.webflow.com/607520e7e5d45121dbcc43a4/608a0fea9cfb929a753150f6_Underfold%20-%20Sean%20Wibi%20(1).jpg",
        subject: "Alumni"
    },
    {
        name: "Jason Lin",
        ATAR: "99.95 ATAR",
        school: "Dux of Knox Grammar",
        line1: ["1st in NSW", "for 3U Maths"],
        line2: ["3rd in NSW", "for Chemistry"],
        line3: ["4th in NSW", "for 4U Maths"],
        img: "https://uploads-ssl.webflow.com/607520e7e5d45121dbcc43a4/608a19d8f3d2f956dab93366_Underfold%20-%20Jason%20Lin.jpg",
        subject: "Alumni"
    },
    {
        name: "Zack Bolland",
        ATAR: "99.80 ATAR",
        school: "Dux of Cammeraygal High",
        line1: ["6th in NSW", "for Economics"],
        line2: ["", "1st in English Adv. (98)"],
        line3: ["", "1st in Economics (98)"],
        img: "https://uploads-ssl.webflow.com/607520e7e5d45121dbcc43a4/626698a2f5b0e1050b0b9ddd_Zack.jpg",
        subject: "Alumni"
    },
    {
        name: "Alicia Pan",
        ATAR: "99.80 ATAR",
        school: "AR at Abbotsleigh",
        line1: ["", "97 in 2U Maths"],
        line2: ["", "95 in English Adv."],
        line3: ["", "95 in Physics"],
        img: "https://uploads-ssl.webflow.com/607520e7e5d45121dbcc43a4/626698a32ae7430713f35986_Alicia.jpg",
        subject: "Alumni"
    },
    {
        name: "Jasmine De Rosa",
        ATAR: "99.60 ATAR",
        school: "AR at North Sydney Girls",
        line1: ["5th in NSW", "for Economics"],
        line2: ["", "1st in Economics (98)"],
        line3: ["", "97 in 2U Maths"],
        img: "https://uploads-ssl.webflow.com/607520e7e5d45121dbcc43a4/629375465593a1472d513216_Jasmine.jpg",
        subject: "Alumni"
    },
    {
        name: "Cory Aitchison",
        ATAR: "99.95 ATAR",
        school: "Dux of Knox Grammar",
        line1: ["6th in NSW", "for Economics"],
        line2: ["12th in NSW", "for Chemistry"],
        line3: ["", "1st in Physics"],
        img: require("./profile/Cory.jpeg"),
        subject: "General"
    },
    {
        name: "Marko Beocanin",
        ATAR: "99.95 ATAR",
        school: "Dux of Normanhurst Boys",
        line1: ["8th in NSW", "for English Adv"],
        line2: ["", "1st in English Adv (98)"],
        line3: ["", "1st in English Ext. 1"],
        img: require("./profile/Marko.jpg"),
        subject: "General"
    },
    {
        name: "Sijin Soon",
        ATAR: "99.85 ATAR",
        uni: "B.Software Eng (Hons)",
        school: "AR at North Sydney Girls",
        line1: ["", "3rd in Chemistry (96)"],
        line2: ["", "1st in 3U Maths (99)"],
        line3: ["", "2nd in 4U Maths (98)"],
        img: require("./profile/SijinSoon.jpg"),
        subject: "General"
    },
    {
        name: "Rishabh Jain",
        ATAR: "99.80 ATAR",
        uni: "B.Eng & B.Sci (Hons)",
        school: "AR at Baulkham Hills",
        line1: ["", "7th in Chemistry (97)"],
        line2: ["", "7th in Physics (95)"],
        line3: ["", "99 in 3U Maths"],
        img: require("./profile/RishJain.jpg"),
        subject: "General"
    },
    {
        name: "Jasmine De Rosa",
        ATAR: "99.60 ATAR",
        school: "AR at North Sydney Girls",
        line1: ["5th in NSW", "for Economics"],
        line2: ["", "1st in Economics (98)"],
        line3: ["", "2nd in Modern History (96)"],
        img: require("./profile/JasmineDeRosa.jpeg"),
        subject: "General"
    },
    {
        name: "Freya Osterberg",
        ATAR: "99.55 ATAR",
        school: "AR at Hornsby Girls",
        line1: ["", "1st in English Adv (96)"],
        line2: ["", "1st in English Ext. 1"],
        line3: ["", "1st in English Ext. 2"],
        img: require("./profile/Freya.jpg"),
        subject: "General"
    },
    {
        name: "Haowen Gao",
        ATAR: "99.85 ATAR",
        uni: "B. Adv. Comp. & Sci",
        school: "AR at Knox Grammar",
        line1: ["8th in NSW", "for 4U Maths"],
        line2: ["", "1st in 4U Maths (99)"],
        line3: ["", "1st in 3U Maths (99)"],
        img: require("./profile/HaowenGao.jpg"),
        subject: "General"
    },
    {
        name: "Nikhila Jayawardena",
        ATAR: "99.90 ATAR",
        uni: "Doctors of Medicine (MD)",
        school: "AR at Baulkham Hills",
        line1: ["", "5th 4U Maths (98)"],
        line2: ["", "3rd in 3U Maths (99)"],
        line3: ["", "3rd in Physics (95)"],
        img: require("./profile/NikhilaJayawardena.jpg"),
        subject: "General"
    },
    {
        name: "Alice Sharma",
        ATAR: "99.55 ATAR",
        school: "AR at North Sydney Girls",
        line1: ["", "1st in English Adv"],
        line2: ["", "2nd in Economics"],
        line3: ["", ""],
        img: require("./profile/AliceSharma.jpg"),
        subject: "General"
    },
    {
        name: "Alan Zhang",
        ATAR: "99.70 ATAR",
        uni: "Doctors of Medicine (MD)",
        school: "AR at Normanhurst Boys",
        line1: ["", "1st in Chemistry (96)"],
        line2: ["", "1st in Biology"],
        line3: ["", "97 in 3U Maths"],
        img: require("./profile/AlanZhang.jpg"),
        subject: "General"
    },
    {
        name: "Freya Osterberg",
        ATAR: "99.55 ATAR",
        school: "AR at Hornsby Girls",
        line1: ["", "1st in English Adv (96)"],
        line2: ["", "1st in English Ext. 1"],
        line3: ["", "1st in English Ext. 2"],
        img: require("./profile/Freya.jpg"),
        subject: "HSCEnglish"
    },
    {
        name: "Marko Beocanin",
        ATAR: "99.95 ATAR",
        school: "Dux of Normanhurst Boys",
        line1: ["8th in NSW", "for English Adv"],
        line2: ["", "1st in English Adv (98)"],
        line3: ["", "1st in English Ext. 1"],
        img: require("./profile/Marko.jpg"),
        subject: "HSCEnglish"
    },
    {
        name: "Alice Sharma",
        ATAR: "99.55 ATAR",
        school: "AR at North Sydney Girls",
        line1: ["", "1st in English Adv"],
        line2: ["", "2nd in Economics"],
        line3: ["", ""],
        img: require("./profile/AliceSharma.jpg"),
        subject: "HSCEnglish"
    },
    {
        name: "Riya Patel",
        ATAR: "99.00 ATAR",
        school: "Dux of Blacktown Girls",
        line1: ["", "1st in English Adv"],
        line2: ["", "1st in Modern History"],
        line3: ["", "1st in Maths Ext. 2"],
        img: require("./profile/RiyaPatel.jpg"),
        subject: "HSCEnglish"
    },
    {
        name: "Bowen Ren",
        ATAR: "99.70 ATAR",
        school: "AR at Epping Boys",
        line1: ["", "4th in English Adv"],
        line2: ["", "3rd in Chemistry"],
        line3: ["", "3rd in Physics"],
        img: require("./profile/BowenRen.jpg"),
        subject: "HSCEnglish"
    },
    {
        name: "Zack Bolland",
        ATAR: "99.80 ATAR",
        school: "Dux of Cammeraygal High",
        line1: ["8th in NSW", "for Economics"],
        line2: ["", "1st in Economics (98)"],
        line3: ["", "1st in English Adv. (98)"],
        img: require("./profile/ZackBolland.jpeg"),
        subject: "HSCEconomics"
    },
    {
        name: "Xerxes Lopes",
        ATAR: "99.65 ATAR",
        school: "AR at Normanhurst Boys",
        line1: ["", "1st in Economics (97)"],
        line2: ["", "1st in Chemistry (97)"],
        line3: ["", "1st in 2U Maths (100)"],
        img: require("./profile/Xerxes.jpeg"),
        subject: "HSCEconomics"
    },
    {
        name: "Jasmine De Rosa",
        ATAR: "99.60 ATAR",
        school: "AR at North Sydney Girls",
        line1: ["5th in NSW", "for Economics"],
        line2: ["", "1st in Economics (98)"],
        line3: ["", "2nd in Modern History (96)"],
        img: require("./profile/JasmineDeRosa.jpeg"),
        subject: "HSCEconomics"
    },
    {
        name: "Matthew O'Dea",
        ATAR: "99.85 ATAR",
        school: "AR at Riverview",
        line1: ["", "2nd in Economics (95)"],
        line2: ["", "12th in English Adv (95)"],
        line3: ["", ""],
        img: require("./profile/MattODea.jpeg"),
        subject: "HSCEconomics"
    },
    {
        name: "Maxwell Han",
        ATAR: "99.85 ATAR",
        school: "AR at Baulkham Hills",
        line1: ["", "5th in Economics (95)"],
        line2: ["", "1st in English Adv. (96)"],
        line3: ["", "1st in English Ext. 1"],
        img: require("./profile/Maxwell.jpeg"),
        subject: "HSCEconomics"
    },
    {
        name: "Freya Osterberg",
        ATAR: "99.55 ATAR",
        school: "AR at Hornsby Girls",
        line1: ["", "1st in Economics (96)"],
        line2: ["", "1st in English Adv (96)"],
        line3: ["", "1st in Legal Studies"],
        img: require("./profile/Freya.jpg"),
        subject: "HSCEconomics"
    },
    {
        name: "Cory Aitchison",
        ATAR: "99.95 ATAR",
        school: "Dux of Knox Grammar",
        line1: ["6th in NSW", "for Economics"],
        line2: ["12th in NSW", "for Chemistry"],
        line3: ["", "1st in Physics (96)"],
        img: require("./profile/Cory.jpeg"),
        subject: "HSCEconomics"
    },
    {
        name: "Vahin Ravi",
        ATAR: "99.35 ATAR",
        school: "AR at Baulkham Hills",
        line1: ["", "8th in Economics"],
        line2: ["", "96 in 3U Maths"],
        line3: ["", "95 in 4U Maths"],
        img: require("./profile/Vahin.jpg"),
        subject: "HSCEconomics"
    },
    {
        name: "Henrik Osterberg",
        ATAR: "99.30 ATAR",
        school: "AR at Normanhurst Boys",
        line1: ["", "93 in Economics"],
        line2: ["", "97 in 3U Maths"],
        line3: ["", "94 in Legal Studies"],
        img: require("./profile/Henrik.jpg"),
        subject: "HSCEconomics"
    },
    {
        name: "Sabina Patawaran",
        ATAR: "99.00 ATAR",
        school: "Dux of Riverside Girls",
        line1: ["", "1st in Economics"],
        line2: ["", "1st in English Adv"],
        line3: ["", "1st in English Ext. 1"],
        img: require("./profile/Sabina.jpg"),
        subject: "HSCEconomics"
    },
    {
        name: "Rishabh Jain",
        ATAR: "99.80 ATAR",
        uni: "B.Eng & B.Sci (Hons)",
        school: "AR at Baulkham Hills",
        line1: ["", "7th in Chemistry (97)"],
        line2: ["", "7th in Physics (95)"],
        line3: ["", "99 in 3U Maths"],
        img: require("./profile/RishJain.jpg"),
        subject: "HSCChemistry"
    },
    {
        name: "Alan Zhang",
        ATAR: "99.70 ATAR",
        uni: "Doctors of Medicine (MD)",
        school: "AR at Normanhurst Boys",
        line1: ["", "1st in Chemistry (96)"],
        line2: ["", "1st in Biology"],
        line3: ["", "97 in 3U Maths"],
        img: require("./profile/AlanZhang.jpg"),
        subject: "HSCChemistry"
    },
    {
        name: "Trisha Nangia",
        ATAR: "99.85 ATAR",
        uni: "B.Eng & B.Sci (Hons)",
        school: "AR at North Sydney Girls",
        line1: ["", "6th in Chemistry (96)"],
        line2: ["", "1st in English Adv"],
        line3: ["", "4th in 3U Maths"],
        img: require("./profile/TrishaNangia.jpg"),
        subject: "HSCChemistry"
    },
    {
        name: "Shirin Shaban",
        ATAR: "99.30 ATAR",
        uni: "Doctors of Medicine (MD)",
        school: "AR at Hornsby Girls",
        line1: ["", "7th in Chemistry (95)"],
        line2: ["", "1st in 2U Maths (99)"],
        line3: ["", ""],
        img: require("./profile/ShirinShaban.jpg"),
        subject: "HSCChemistry"
    },
    {
        name: "Grace Shim",
        ATAR: "98.85 ATAR",
        uni: "B.Eng & B.Sci (Hons)",
        school: "AR at North Sydney Girls",
        line1: ["", "95 in Chemistry"],
        line2: ["", "98 in 3U Maths"],
        line3: ["", ""],
        img: require("./profile/GraceShim.jpg"),
        subject: "HSCChemistry"
    },
    {
        name: "Noah Chanelian",
        ATAR: "99.60 ATAR",
        uni: "B.Eng & B.Sci (Hons)",
        school: "AR at Epping Boys",
        line1: ["", "1st in Chemistry (96)"],
        line2: ["", "4th in 4U Maths (96)"],
        line3: ["", "5th in 3u Maths (97)"],
        img: require("./profile/NoahChanelian.jpg"),
        subject: "HSCChemistry"
    },
    {
        name: "Sijin Soon",
        ATAR: "99.85 ATAR",
        uni: "B.SoftwareEng (Hons)",
        school: "AR at North Sydney Girls",
        line1: ["", "3rd in Chemistry (96)"],
        line2: ["", "1st in 3U Maths (99)"],
        line3: ["", "2nd in 4U Maths (98)"],
        img: require("./profile/SijinSoon.jpg"),
        subject: "HSCChemistry"
    },
    {
        name: "Xerxes Lopes",
        ATAR: "99.65 ATAR",
        school: "AR at Normanhurst Boys",
        line1: ["", "1st in Chemistry (97)"],
        line2: ["", "1st in Economics (97)"],
        line3: ["", "1st in 2U Maths (100)"],
        img: require("./profile/Xerxes.jpeg"),
        subject: "HSCChemistry"
    },
    {
        name: "Cory Aitchison",
        ATAR: "99.95 ATAR",
        uni: "B.Eng & B.Sci (Hons)",
        school: "Dux of Knox Grammar",
        line1: ["12th in NSW", "for Chemistry"],
        line2: ["6th in NSW", "for Economics"],
        line3: ["", "1st in Physics (96)"],
        img: require("./profile/CoryAitchison.jpg"),
        subject: "HSCPhysics"
    },
    {
        name: "Alicia Pan",
        ATAR: "99.80 ATAR",
        uni: "Doctors of Medicine (MD)",
        school: "AR at Abbotsleigh",
        line1: ["", "95 in Physics"],
        line2: ["", "97 in English Adv."],
        line3: ["", "97 in 2U Maths"],
        img: require("./profile/AliciaPan.jpg"),
        subject: "HSCPhysics"
    },
    {
        name: "Ed Medlock",
        ATAR: "98.95 ATAR",
        uni: "B.Sc (Physics)",
        school: "AR at SHORE",
        line1: ["", "4th in Physics (95)"],
        line2: ["", "4th in Chemistry (95)"],
        line3: ["", "6th in 4U Maths"],
        img: require("./profile/EdMedlock.jpg"),
        subject: "HSCPhysics"
    },
    {
        name: "Anna Pahlman",
        ATAR: "99.25 ATAR",
        uni: "Doctors of Medicine (MD)",
        school: "AR at Chatswood High",
        line1: ["", "1st in Physics (95)"],
        line2: ["", "1st in Chemistry (95)"],
        line3: ["", ""],
        img: require("./profile/AnnaPahlman.jpg"),
        subject: "HSCPhysics"
    },
    {
        name: "Lakshya Rao",
        ATAR: "99.65 ATAR",
        uni: "B.Comm (Actl) (Coop)",
        school: "AR at Normanhurst Boys",
        line1: ["", "3rd in Physics (95)"],
        line2: ["", "3rd in Chemistry (95)"],
        line3: ["", "5th in 3U Maths (98)"],
        img: require("./profile/LakshyaRao.jpg"),
        subject: "HSCPhysics"
    },
    {
        name: "Alex Loustau",
        ATAR: "99.45 ATAR",
        uni: "B.Sci (Physics)",
        school: "AR at Normanhurst Boys",
        line1: ["", "2nd in Physics (97)"],
        line2: ["", "7th in Chemistry (97)"],
        line3: ["", "2nd in Biology (95)"],
        img: require("./profile/AlexLoustau.jpg"),
        subject: "HSCPhysics"
    },
    {
        name: "Sepehr Saryazdi",
        ATAR: "98.00 ATAR",
        school: "AR at Model Farms School",
        line1: ["", "2nd in Physics"],
        line2: ["", "3rd in Science Ext"],
        line3: ["", "1st 4U Maths"],
        img: require("./profile/SepehrSaryazdi.jpg"),
        subject: "HSCPhysics"
    },
    {
        name: "Haowen Gao",
        ATAR: "99.85 ATAR",
        uni: "B. Adv. Comp. & Sci",
        school: "AR at Knox Grammar",
        line1: ["8th in NSW", "for 4U Maths"],
        line2: ["", "1st in 4U Maths (99)"],
        line3: ["", "1st in 3U Maths (99)"],
        img: require("./profile/HaowenGao.jpg"),
        subject: "HSC4UMaths"
    },
    {
        name: "Nikhila Jayawardena",
        ATAR: "99.90 ATAR",
        uni: "Doctors of Medicine (MD)",
        school: "AR at Baulkham Hills",
        line1: ["", "5th in 4U Maths (98)"],
        line2: ["", "3rd in 3U Maths (99)"],
        line3: ["", "3rd in Physics (95)"],
        img: require("./profile/NikhilaJayawardena.jpg"),
        subject: "HSC4UMaths"
    },
    {
        name: "Sijin Soon",
        ATAR: "99.85 ATAR",
        uni: "B.SoftwareEng (Hons)",
        school: "AR at North Sydney Girls",
        line1: ["", "2nd in 4U Maths (98)"],
        line2: ["", "1st in 3U Maths (99)"],
        line3: ["", "3rd in Chemistry (96)"],
        img: require("./profile/SijinSoon.jpg"),
        subject: "HSC4UMaths"
    },
    {
        name: "Ricky Huang",
        ATAR: "99.40 ATAR",
        uni: "B.Actl Studies",
        school: "AR at North Sydney Boys",
        line1: ["", "98 in 4U Maths"],
        line2: ["", "97 in 3U Maths"],
        line3: ["", ""],
        img: require("./profile/RickyHuang.jpg"),
        subject: "HSC4UMaths"
    },
    {
        name: "Mrinaank Sinha",
        ATAR: "99.75 ATAR",
        uni: "B.Eng (CS & Mech)",
        school: "Dux of Chatswood High",
        line1: ["1st in NSW", "for SDD"],
        line2: ["", "1st in 4U Maths (95)"],
        line3: ["", "1st in 3U Maths (98)"],
        img: require("./profile/MrinaankSinha.jpg"),
        subject: "HSC4UMaths"
    },
    {
        name: "Tom Abbott",
        ATAR: "99.25 ATAR",
        uni: "B.Sci (Maths)",
        school: "AR at Barker College",
        line1: ["5th in NSW", "for Science Ext"],
        line2: ["", "94 in 4U Maths"],
        line3: ["", "96 in 3U Maths"],
        img: require("./profile/TomAbbott.jpg"),
        subject: "HSC4UMaths"
    },
    {
        name: "Rishabh Jain",
        ATAR: "99.80 ATAR",
        uni: "B.Eng & B.Sci (Hons)",
        school: "AR at Baulkham Hills",
        line1: ["", "15th in 3U Maths (99)"],
        line2: ["", "13th in 4U Maths (96)"],
        line3: ["", "7th in Chemistry (97)"],
        img: require("./profile/RishJain.jpg"),
        subject: "HSC3UMaths"
    },
    {
        name: "Sijin Soon",
        ATAR: "99.85 ATAR",
        uni: "B.SoftwareEng (Hons)",
        school: "AR at North Sydney Girls",
        line1: ["", "1st in 3U Maths (99)"],
        line2: ["", "2nd in 4U Maths (98)"],
        line3: ["", "3rd in Chemistry (96)"],
        img: require("./profile/SijinSoon.jpg"),
        subject: "HSC3UMaths"
    },
    {
        name: "Lakshya Rao",
        ATAR: "99.65 ATAR",
        uni: "B.Comm (Actl) (Coop)",
        school: "AR at Normanhurst Boys",
        line1: ["", "5th in 3U Maths (98)"],
        line2: ["", "3rd in Chemistry (95)"],
        line3: ["", "3rd in Physics (95)"],
        img: require("./profile/LakshyaRao.jpg"),
        subject: "HSC3UMaths"
    },
    {
        name: "Ben Lama",
        ATAR: "98.00 ATAR",
        uni: "Masters of Maths Sci",
        school: "AR at St Andrews College",
        line1: ["", "1st in 3U Maths (94)"],
        line2: ["", "1st in 4U Maths (94)"],
        line3: ["", "2nd in Economics"],
        img: require("./profile/BenLama.jpg"),
        subject: "HSC3UMaths"
    },
    {
        name: "Ricky Huang",
        ATAR: "99.40 ATAR",
        uni: "B.Actl Studies",
        school: "AR at North Sydney Boys",
        line1: ["", "97 in 3U Maths"],
        line2: ["", "98 in 4U Maths"],
        line3: ["", ""],
        img: require("./profile/RickyHuang.jpg"),
        subject: "HSC3UMaths"
    },
    {
        name: "Tiya Banerjee",
        ATAR: "99.65 ATAR",
        uni: "B.Sci (Maths)",
        school: "AR at North Sydney Girls",
        line1: ["", "4th in 3U Maths (99)"],
        line2: ["", "2nd in 4U Maths (95)"],
        line3: ["", "1st in Classical Greek"],
        img: require("./profile/TiyaBanerjee.jpg"),
        subject: "HSC3UMaths"
    },
    {
        name: "Braydon Liu",
        ATAR: "99.25 ATAR",
        uni: "B.Adv Maths (Hons)",
        school: "AR at Normanhurst Boys",
        line1: ["", "2nd in 3U Maths (99)"],
        line2: ["", "1st in 4U Maths (96)"],
        line3: ["", "5th in Physics (95)"],
        img: require("./profile/BraydonLiu.jpg"),
        subject: "HSC3UMaths"
    },
    {
        name: "Annabel Chen",
        ATAR: "99.80 ATAR",
        uni: "B.Sci (Hons)",
        school: "AR at North Sydney Girls",
        line1: ["", "7th in 3U Maths (97)"],
        line2: ["", "1st in English Adv (97)"],
        line3: ["", "3rd in Chemistry (96)"],
        img: require("./profile/AnnabelChen.jpg"),
        subject: "HSC3UMaths"
    },
    {
        name: "Michelle Zhang",
        ATAR: "99.05 ATAR",
        uni: "B.Sci (Hons)",
        school: "AR at North Sydney Girls",
        line1: ["", "96 in 3U Maths"],
        line2: ["", "98 in 2U Maths"],
        line3: ["", "96 in Modern History"],
        img: require("./profile/MichelleZhang.jpg"),
        subject: "HSC3UMaths"
    },
    {
        name: "Tom Abbott",
        ATAR: "99.25 ATAR",
        uni: "B.Sci (Maths)",
        school: "AR at Barker College",
        line1: ["5th in NSW", "for Science Ext"],
        line2: ["", "96 in 3U Maths"],
        line3: ["", "94 in 4U Maths"],
        img: require("./profile/TomAbbott.jpg"),
        subject: "HSC3UMaths"
    },
    {
        name: "Shreyank Sinha",
        ATAR: "98.10 ATAR",
        uni: "B.Sci (Adv Maths)",
        school: "AR at Normanhurst Boys",
        line1: ["", "96 in 2U Maths"],
        line2: ["", "92 in 3U Maths"],
        line3: ["", "94 in Chemistry"],
        img: require("./profile/ShreyankSinha.jpg"),
        subject: "HSC2UMaths"
    },
    {
        name: "Anna Yuan",
        ATAR: "98.55 ATAR",
        uni: "B.Sci (Maths)",
        school: "AR at Roseville College",
        line1: ["", "96 in 2U Maths"],
        line2: ["", "96 in 3U Maths"],
        line3: ["", "97 in Business Studies"],
        img: require("./profile/AnnaYuan.jpg"),
        subject: "HSC2UMaths"
    },
    {
        name: "Henrik Osterberg",
        ATAR: "99.30 ATAR",
        uni: "B.Adv (Hons)",
        school: "AR at Normanhurst Boys",
        line1: ["", "97 in 3U Maths"],
        line2: ["", "93 in 4U Maths"],
        line3: ["", ""],
        img: require("./profile/HenrikOsterberg.jpg"),
        subject: "HSC2UMaths"
    },
    {
        name: "Braydon Liu",
        ATAR: "99.25 ATAR",
        uni: "B.Adv Maths (Hons)",
        school: "AR at Normanhurst Boys",
        line1: ["", "2nd in 3U Maths (99)"],
        line2: ["", "1st in 4U Maths (96)"],
        line3: ["", "5th in Physics (95)"],
        img: require("./profile/BraydonLiu.jpg"),
        subject: "HSC2UMaths"
    },
    {
        name: "Annabel Chen",
        ATAR: "99.80 ATAR",
        uni: "B.Sci (Hons)",
        school: "AR at North Sydney Girls",
        line1: ["", "7th in 3U Maths (97)"],
        line2: ["", "1st in English Adv (97)"],
        line3: ["", "3rd in Chemistry (96)"],
        img: require("./profile/AnnabelChen.jpg"),
        subject: "HSC2UMaths"
    },
    {
        name: "Michelle Zhang",
        ATAR: "99.05 ATAR",
        uni: "B.Sci (Hons)",
        school: "AR at North Sydney Girls",
        line1: ["", "98 in 2U Maths"],
        line2: ["", "96 in 3U Maths"],
        line3: ["", "96 in Modern History"],
        img: require("./profile/MichelleZhang.jpg"),
        subject: "HSC2UMaths"
    },
    {
        name: "Tiya Banerjee",
        ATAR: "99.65 ATAR",
        uni: "B.Sci (Maths)",
        school: "AR at North Sydney Girls",
        line1: ["", "4th in 3U Maths (99)"],
        line2: ["", "2nd in 4U Maths (95)"],
        line3: ["", "1st in Classical Greek"],
        img: require("./profile/TiyaBanerjee.jpg"),
        subject: "HSC2UMaths"
    },
    {
        name: "Aidan Or",
        ATAR: "99.60 ATAR",
        uni: "B.Software Eng (Hons)",
        school: "AR at Baulkham Hills",
        line1: ["", "98 in 3U Maths"],
        line2: ["", "97 in 4U Maths"],
        line3: ["", ""],
        img: require("./profile/AidanOr.jpg"),
        subject: "JuniorMaths"
    },
    {
        name: "Hayley Yong",
        ATAR: "98.30 ATAR",
        uni: "B.AppliedSci",
        school: "AR at NBSC Manly Campus",
        line1: ["", "95 in 2U Maths"],
        line2: ["", "92 in 3U Maths"],
        line3: ["", ""],
        img: require("./profile/HayleyYong.jpg"),
        subject: "JuniorMaths"
    },
    {
        name: "Michelle Zhang",
        ATAR: "99.05 ATAR",
        uni: "B.Sci (Hons)",
        school: "AR at North Sydney Girls",
        line1: ["", "96 in 3U Maths"],
        line2: ["", "98 in 2U Maths"],
        line3: ["", "96 in Modern History"],
        img: require("./profile/MichelleZhang.jpg"),
        subject: "JuniorMaths"
    },
    {
        name: "Sidharth KN",
        ATAR: "92.65 ATAR",
        uni: "B.Comm",
        school: "Syd. Secondary College",
        line1: ["", "93 in 4U Maths"],
        line2: ["", "92 in 3U Maths"],
        line3: ["", ""],
        img: require("./profile/SidharthKN.jpg"),
        subject: "JuniorMaths"
    },
    {
        name: "Kaitlyn Hua",
        ATAR: "97.45 ATAR",
        uni: "B.Comm",
        school: "North Sydney Girls",
        line1: ["", "94 in 4U Maths"],
        line2: ["", "91 in 3U Maths"],
        line3: ["", ""],
        img: require("./profile/KaitlynHua.jpg"),
        subject: "JuniorMaths"
    },
    {
        name: "Harrison Chong",
        ATAR: "99.50 ATAR",
        uni: "B.Comm",
        school: "Dux of Chatswood High",
        line1: ["", "1st in 3U Maths (100)"],
        line2: ["", "1st in 2u Maths (99)"],
        line3: ["", ""],
        img: require("./profile/HarrisonChong.jpg"),
        subject: "AlumniTeacher"
    },
    {
        name: "Marlon Randeniya",
        ATAR: "99.55 ATAR",
        uni: "Doctors of Medicine (MD)",
        school: "Dux of Chatswood High",
        line1: ["", "1st in Chemistry"],
        line2: ["", "2nd in Physics"],
        line3: ["", ""],
        img: require("./profile/MarlonRandeniya.jpg"),
        subject: "AlumniTeacher"
    },
    
    {
        name: "Nicole Kitrungrot",
        ATAR: "99.05 ATAR",
        uni: "B.Comm",
        school: "AR at Chatswood High",
        line1: ["20th in NSW", "for Business St."],
        line2: ["", "1st in Business Studies"],
        line3: ["", "1st in Maths Standard"],
        img: require("./profile/NicoleKitrungrot.jpg"),
        subject: "Tutor"
    },
    {
        name: "Robbie Ferguson",
        ATAR: "99.95 ATAR",
        uni: "B.CompSci",
        school: "Dux of Knox Grammar",
        line1: ["7th in NSW", "for English Adv"],
        line2: ["7th in NSW", "for English Ext. 1"],
        line3: ["5th in NSW", "for English Ext. 2"],
        img: require("./profile/RobbieFerguson.jpg"),
        subject: "Special Alumni"
    },
]

export {
    allProfiles
}