import React, { useState, useEffect, handleResize } from 'react'
import "../components/Timetable.scss"
import {GetTimetable, CampusCalc, CapacityStatus} from "../assets/GetTimetable.js"
import { Helmet } from 'react-helmet-async';

function Timetable() {
    var timetableArray = GetTimetable();
    const [filterTags, setFilterTags] = useState([])
    var filterTimetable = [];
    var checked = 1;

    //Filtering
    if(filterTags.length > 0) {
        timetableArray.filter((n) => {
            filterTags.map((filterTag) => {
                if (n[0].includes(filterTag)){
                    filterTimetable.push(n);
                }
            })
        })
    } else {
        filterTimetable = timetableArray;
    }
        
    const filterHandler = (event) => {
        if (event.target.checked) {
            setFilterTags([...filterTags, event.target.value])
        } else {
            setFilterTags(
                filterTags.filter((filterTag) => filterTag !== event.target.value)
            )
        }
    }

    //split array of subjects by year
    var year12Array = [];
    var year11Array = [];
    var juniorArray = [];
    
    timetableArray?.map((i) => {
        if (i[0][0].includes("HSC")) {year12Array.push(i);}
        if (i[0][0].includes("11")) {year11Array.push(i);}
        if (i[0][0].includes("10")) {juniorArray.push(i);}
        if (i[0][0].includes("09")) {juniorArray.push(i);}
    })

    //draw open/close
    const [showDraw, setShowDraw] = useState(1);
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 767);

    const handleResize = () => {
        setIsMobile(window.innerWidth <= 767);
    }

    useEffect(() => {
        window.addEventListener("resize", handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        }
        
    }, []);

    function toggleDraw() {
        setIsMobile(!isMobile);
        //setFilterTags([])   
        if (isMobile) document.body.style.overflow="hidden";
        if (!isMobile) document.body.style.overflow="";
    }


    //repopulate the checked checkboxes based on what's in filterTags array
    useEffect(()=> {
        filterTags.map((n) => {
            var checkInput = document.getElementById(n.replace(/\s/g, ''));
            if(checkInput) checkInput.checked = 1;
        })
    })

  return (
    <>
        <Helmet>
            <meta name='robots' content='nofollow, nofollow' />
        </Helmet>
        <div className="container timetable-override">
            <div className="timetable-container">
                <div className="timetable-left">
                    <div className="timetable-left-top">
                        <div className="timetable-left-top-group">
                            <h2>Timetable</h2>
                            <div className="timetable-description">Project Academy's timetable for Year 9 to 12.</div>
                        </div>
                        <div className="timetable-filter" onClick={toggleDraw}>Filters</div>
                    </div>
                    {/* <div className="campus-filters"></div> */}

                    {!isMobile &&
                    <>
                        <div className="timetable-drawer">
                                <p className="timetable-filters">Filters</p>
                                <div className="year12Timetable">
                                    {year12Array?.map((i) => (
                                        <>
                                            <label className="timetable-label">     
                                                <input
                                                    type="checkbox"
                                                    defaultChecked={!checked}
                                                    onChange={filterHandler}
                                                    value={i[0][0]}
                                                    id={i[0][0].replace(/\s/g, '')}
                                                />  
                                                <p>{i[0][0]}</p> 
                                            </label>            
                                        </>
                                    ))}
                                </div>
                                
                                <div className="year11Timetable">
                                    {year11Array?.map((i) => (
                                        <>
                                            <label className="timetable-label">     
                                                <input
                                                    type="checkbox"
                                                    onChange={filterHandler}
                                                    value={i[0][0]}
                                                    id={i[0][0].replace(/\s/g, '')}
                                                />  
                                                <p>{i[0][0]}</p> 
                                            </label>            
                                        </>
                                    ))}
                                </div>

                                <div className="juniorTimetable">
                                    {juniorArray?.map((i) => (
                                        <>
                                            <label className="timetable-label">     
                                                <input
                                                    type="checkbox"
                                                    onChange={filterHandler}
                                                    value={i[0][0]}
                                                    id={i[0][0].replace(/\s/g, '')}
                                                />  
                                                <p>{i[0][0]}</p> 
                                            </label>            
                                        </>
                                    ))}
                                </div>
                        </div>
                        <div className="timetable-drawer-cover" onClick={toggleDraw}></div>
                    </>
                    }
                </div>
                <div className="timetable-right">
                {filterTimetable?.map((i) => (
                    <>
                        <p className="timetable-subject-heading purple">{i[0][0]}</p>
                        <div className="timetable-group">
                        {i.map((j) => (
                            <>
                                <div className="timetable-slot">
                                    <p id="timetable-time-desktop" className="timetable-time">{j[3]} {j[2]}</p>
                                    <p id="timetable-time-mobile" className="timetable-time">{j[3].substring(0,3)}<span className="timetable-time-pm">&nbsp;{j[2].replace(/\s/g, '')}</span></p>
                                    <p className="timetable-stream">
                                        {j[1] 
                                        ? `Text: ${j[1]}`
                                        : "Normal stream class"
                                        }
                                    </p>
                                    <CampusCalc campus={j[5]} />
                                    <CapacityStatus capacity={j[7]} />
                                </div>
                            </>
                        ))}
                        </div>
                    </>
                ))}
                    
                </div>
            </div>
        </div>
    </>
  )
}

export default Timetable