import React from 'react'

function Banner() {
  return (
    <div className="banner">
          <p>
            <a href="https://app.projectacademy.nsw.edu.au/enquiries">
              Announcement: Enrolments for T2 2023 are open! 🎉
            </a>
          </p>
    </div>
  )
}

export default Banner